import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { News } from '../../models';

@Component({
  selector: 'app-news-list-card',
  templateUrl: './news-list-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsListCardComponent implements OnInit {
  @Input() newsItem: News;
  constructor() {}

  ngOnInit(): void {}
}

<article class="content-page" *ngIf="page$ | async as page">
  <ng-container *ngIf="pageLoad$ | async">
    <app-header
      [fixedImage]="true"
      [lazyLoading]="'eager'"
      *ngIf="page.imageUrl || page.videoUrl"
      [cssClass]="page.imageUrl ? '' : 'header--no-image'"
      [videoBg]="page.videoUrl"
      [imageUrl]="page.imageUrl ? page.imageUrl : false"
      (inView)="(true)"
      [title]="page.title"
    ></app-header>

    <!-- <div class="container-xs" *ngIf="!page.imageUrl || !page.videoUrl">
      <h1 class="h1 content-page__title">{{ page.title }}</h1>
    </div> -->
    <section class="spacer-bottom-l">
      <div class="container-xxs">
        <div class="markdown-content" *ngIf="page.intro"><strong [innerHTML]="page.intro"></strong></div>
        <br />
        <div class="markdown-content" [innerHTML]="page.text | stripEmbed: (cookiesAccepted$ | async) | safe" fstrDynamicContent></div>
      </div>
    </section>
  </ng-container>
</article>

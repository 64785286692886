import { Component, OnInit, Inject, PLATFORM_ID, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { ContentPage } from '../../models';
import { Store, select } from '@ngrx/store';
import { ContentState, getContentPagesLoading } from '../../store';
import { isPlatformBrowser } from '@angular/common';
import * as selectors from '../../store/selectors/content-pages.selector';
import { tap, takeUntil } from 'rxjs/operators';
import { SetTopbarConfig, getRouterState } from '../../../store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPageComponent implements OnInit, OnDestroy {
  page$: Observable<ContentPage>;
  cookiesAccepted$: Observable<CookiePreferences>;
  private _parent$: Observable<ContentPage>;
  private _unsubscribe$ = new Subject<void>();
  pageLoad$: Observable<any>;

  constructor(@Inject(PLATFORM_ID) private platformId, private store: Store<ContentState>) {
    // this._parent$ = this.store.select(selectors.getSelectedParentPage).pipe(takeUntil(this._unsubscribe$));
  }

  ngOnInit(): void {
    this.page$ = this.store.select(selectors.getSelectedContentPage);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    this.cookiesAccepted$ = this.store.select(getCookiePreferences);
    this.pageLoad$ = this.store.select(selectors.getContentPagesLoaded);

    combineLatest([this.page$, this.store.select(getRouterState)])
      // this.page$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(([page, route]) => {
        // .subscribe(page => {
        if (page) {
          this.store.dispatch(
            SetTopbarConfig({
              topbar: {
                title: page.title,
                newRow: true,
                setNoBg: true,
              },
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}

<article class="office-contact-card">
  <h1 class="mb-1 office-contact-card__title" *ngIf="contact.name">{{ contact.name }}</h1>
  <div class="office-contact-card__content">
    <p class="office-contact-card__text mb-0">{{ contact.address }}</p>
    <ul class="office-contact-card__list mb-0 d-flex">
      <li class="office-contact-card__list__item d-flex mb-0" *ngFor="let item of contact.contacts">
        <span *ngIf="item.name">{{ item.name }}</span>
        <span *ngIf="item.title">{{ item.title }}</span>
        <a class="phone-link" [href]="'tel://' + item.phone" *ngIf="item.phone">{{ item.phone }}</a>
        <a [href]="'mailto:' + item.email" *ngIf="item.email">{{ item.email }}</a>
      </li>
    </ul>
  </div>
</article>

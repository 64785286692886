import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, ViewChild } from '@angular/core';
import { Job } from '../../models';

@Component({
  selector: 'app-jobs-card',
  templateUrl: './jobs-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsCardComponent implements OnInit {
  @Input() job: Job;
  active = false;
  @ViewChild('jobscard') el: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.active = !this.active;

    if (!this.active) {
      // scrollback to top with small timeout
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 600);
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentPageComponent } from './containers/content-page/content-page.component';
import { ContentPageMetaGuard } from './guards/content-page-meta.guard';
import { NewsOverviewComponent } from './containers/news-overview/news-overview.component';
import { NewsGuard } from './guards/news.guard';
import { NewsDetailComponent } from './containers/news-detail/news-detail.component';
import { NewsItemGuard } from './guards/news-item.guard';
import { ContactPageComponent } from './containers/contact-page/contact-page.component';
import { ContentBlocksGuard } from '../content-block/guards/content-blocks.guard';
import { NewsCategoriesGuard } from './guards/news-categories.guard';
import { OfficesGuard } from './guards/offices.guard';
import { NewsItemProjectsGuard } from './guards/news-item-projects.guard';
import { NewsItemRelatedGuard } from './guards/news-item-related.guard';
import { JobsPageComponent } from './containers/jobs-page/jobs-page.component';
import { JobsGuard } from './guards/jobs.guard';
import { ContactEmployeesGuard } from '../people/guards/contact-employees.guard';

const routes: Routes = [
  {
    path: 'page/:pageId/:target',
    component: ContentPageComponent,
    canActivate: [ContentPageMetaGuard],
    data: {
      dark: false,
      topbar: {
        setNoBg: true,
      },
    },
  },
  // {
  //   path: 'page/:parentPageId/:parentTarget/:pageId/:target',
  //   component: ContentPageComponent,
  //   canActivate: [ContentPageMetaGuard],
  // },
  {
    path: 'contact',
    component: ContactPageComponent,
    canActivate: [ContentPageMetaGuard, ContentBlocksGuard, OfficesGuard, ContactEmployeesGuard],
    canDeactivate: [ContactEmployeesGuard],
    data: {
      pageId: 2,
      topbar: {
        title: 'Contact',
        setRow: false,
      },
    },
  },
  {
    path: 'jobs',
    component: JobsPageComponent,
    canActivate: [ContentBlocksGuard],
    data: {
      pageId: 18,
      topbar: {
        title: 'Jobs',
        setRow: false,
      },
    },
  },
  {
    path: 'news',
    children: [
      {
        path: '',
        component: NewsOverviewComponent,
        canActivate: [NewsGuard, NewsCategoriesGuard],
        canDeactivate: [NewsGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        data: {
          topbar: {
            title: 'News',
            setRow: false,
          },
        },
      },
      {
        path: ':newsId/:title',
        component: NewsDetailComponent,
        canActivate: [NewsGuard, NewsCategoriesGuard, NewsItemProjectsGuard, NewsItemRelatedGuard, NewsItemGuard],
        canDeactivate: [NewsGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        data: {
          topbar: {
            title: 'News',
            routerLink: ['/news'],
            setRow: false,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}

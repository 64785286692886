<article class="content-page news-detail spacer-bottom-l mb-3" *ngIf="news$ | async as page">
  <div class="container-md">
    <header class="contept-page__header news-detail__header">
      <h1 class="news-detail__title">{{ page.title }}</h1>
      <strong class="h1 text-muted news-detail__date">{{ page.updatedOn | date: 'dd.MM.yyyy' }}</strong>
    </header>
    <div class="news-detail__slider" *ngIf="page.imageUrl || page.gallery">
      <app-header
        *ngIf="page.imageUrl && page.gallery?.length < 1"
        [imageUrl]="page.imageUrl"
        [lazyLoading]="'eager'"
        [imageAnchor]="page.imageAnchor"
        [imageSizes]="headerImageSizes"
        [title]="null"
        [subtitle]="null"
      ></app-header>

      <app-slider [cursorArrow]="false" [total]="page.imageUrl ? page.gallery?.length + 1 :  page.gallery?.length" [config]="sliderConfig" *ngIf="page.gallery?.length > 0">
        <app-header
          *ngIf="page.imageUrl"
          [imageUrl]="page.imageUrl"
          [imageAnchor]="page.imageAnchor"
          [imageSizes]="headerImageSizes"
          [title]="null"
          [subtitle]="null"
        ></app-header>
        <app-header
          *ngFor="let item of page.gallery"
          [imageUrl]="item.thumbnail"
          [title]="null"
          [imageSizes]="headerImageSizes"
          [subtitle]="null"
        ></app-header>
      </app-slider>
    </div>
    <section class="news-detail__content">
      <div class="markdown-content type-body-medium intro-text spacer-bottom-s" *ngIf="page.intro">
        <strong [innerHTML]="page.intro"></strong>
      </div>
      <br />
      <div class="markdown-content" [innerHTML]="page.text | stripEmbed: (cookiesAccepted$ | async) | safe" fstrDynamicContent></div>

      <div class="news-detail__credits text-muted spacer-top-s text-pre-line" *ngIf="page.credits">{{ page.credits }}</div>
    </section>
    <div class="news-detail__buttons spacer-top-l" *ngIf="page.buttonText">
      <a class="button color-black button--border button--small" [href]="page.buttonUrl">
        <span>{{ page.buttonText }}</span>
      </a>
    </div>
  </div>
  <div class="container-xxl news-detail__related-news spacer-top-xl" *ngIf="(related$ | async)?.length">
    <app-related-news [news]="related$ | async"></app-related-news>
  </div>

  <div class="container-xxl news-detail__related-projects spacer-top-xl" *ngIf="(projects$ | async)?.length">
    <app-related-projects [projects]="projects$ | async"></app-related-projects>
  </div>
</article>

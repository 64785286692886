<article class="jobs-page content-page" *ngIf="page$ | async as page">
  <app-header
    [fixedImage]="true"
    [lazyLoading]="'eager'"
    class="contact__header"
    [imageUrl]="page.imageUrl"
    [imageAnchor]="page.imageAnchor"
    [videoBg]="page.videoUrl"
    [title]="page.title"
    *ngIf="page.imageUrl"
  ></app-header>

  <section class="spacer-top-l project-page__content container-xxl">
    <div class="row">
      <div class="col-lg-10 mb-6 mb-lg-0 jobs-page__content">
        <div class="markdown-content" *ngIf="page.intro"><strong class="text-pre-line" [innerHTML]="page.intro"></strong></div>
      </div>
      <br />
      <div class="col-lg-12 offset-lg-2 jobs-page__jobs">
        <ng-container *ngIf="!(loading$ | async)">
          <!-- <div class="markdown-content" *ngIf="page.intro"><strong [innerHTML]="page.intro"></strong></div> -->
          <ul class="jobs-page__list" *ngIf="jobs$ | async as jobs">
            <li class="jobs-page__list__item spacer-bottom-l" *ngFor="let job of jobs">
              <app-jobs-card [job]="job"></app-jobs-card>
            </li>

            <li class="jobs-page__list__item spacing-top-xl spacing-bottom-xl" *ngIf="jobs.length < 1">
              {{ 'jobs-no-result-text' | fromDictionary }}
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="loading$ | async">
          {{ 'jobs-loading-text' | fromDictionary }}
        </ng-container>
      </div>
    </div>
  </section>

  <div class="container-xxl spacer-bottom-l" *ngIf="(contentBlocks$ | async).length">
    <ng-container *ngFor="let block of contentBlocks$ | async; let i = index" [ngSwitch]="block.blockType">
      <app-simple-image *ngSwitchCase="'block--text'" [contentBlock]="block"></app-simple-image>
    </ng-container>
  </div>
</article>

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContentPage, Job } from '../../models';
import { ContentState, getAllJobs, getSelectedContentPage } from '../../store';
import * as selectors from '../../store/selectors/jobs.selector';
import * as fromStore from '../../store';
import { SetTitle } from '../../../store';
import { ContentBlock } from '../../../content-block/models';
import { getSelectedContentBlocks } from '../../../content-block/store';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-jobs-page',
  templateUrl: './jobs-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsPageComponent implements OnInit {
  contentBlocks$: Observable<ContentBlock[]>;
  page$: Observable<ContentPage>;
  jobs$: Observable<Job[]>;
  loading$: Observable<any>;
  constructor(private store: Store<ContentState>, private cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId) {}

  ngOnInit(): void {
    this.contentBlocks$ = this.store.select(getSelectedContentBlocks);
    this.page$ = this.store.select(getSelectedContentPage);
    this.loading$ = this.store.select(selectors.getJobsLoading);
    this.jobs$ = this.store.select(selectors.getAllJobs);

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    this.store.select(selectors.getJobsLoaded).subscribe(l => {
      if (!l) {
        this.store.dispatch(fromStore.LoadJobs());
      }
    });

    this.store.dispatch(SetTitle({ title: `Jobs | KCAP` }));
    // this.jobs$ = this.store.select(getAllJobs);
    // this.jobs$ = this.store.select(getAllJobs);
  }
}

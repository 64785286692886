<article class="jobs-card" #jobscard>
  <h1 class="jobs-card__title h5 text-capitalize">
    <a [href]="job.careers_url" *ngIf="false" target="_blank">{{ job.title }}</a>
    <button (click)="toggle()" *ngIf="false">{{ job.title }}</button>
    <span>{{ job.title }}</span>
  </h1>
  <ul class="jobs-card__meta">
    <li class="jobs-card__meta__item" *ngIf="job.employment_type_code">
      Dedication:
      <span class="text-capitalize">{{ job.employment_type_code }}</span>
    </li>
    <li class="jobs-card__meta__item" *ngIf="job.department">Department: {{ job.department }}</li>
    <li class="jobs-card__meta__item" *ngIf="job.location">Location: {{ job.location }}</li>
  </ul>
  <main class="jobs-card__content" [class.set--active]="active">
    <div class="jobs-card__content__text markdown-content" [innerHTML]="job.description"></div>
  </main>

  <footer class="button-group jobs-card__footer" [ngClass]="active ? 'spacer-bottom-xl spacer-top-s' : ''">
    <!-- <button
      [ngClass]="active ? 'button button--small color-black button--border' : 'button-link color-gray-500'"
      (click)="toggle()"
      target="_blank"
    >
      {{ active ? 'Close' : 'Read more' }}
    </button> -->
    <!-- <a class="button button--small color-black button--border" *ngIf="active" [href]="job.careers_url" target="_blank">
      Apply for this job
    </a> -->
    <!-- <a class="button-link color-gray-500" [href]="job.careers_url" target="_blank">Read more</a> -->
    <a class="button button--border color-black button--small" [href]="job.careers_url" target="_blank">Apply</a>
  </footer>
</article>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromContent from './store';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { ContentPageComponent } from './containers/content-page/content-page.component';
import { CoreModule } from '../core/core.module';
import { NewsOverviewComponent } from './containers/news-overview/news-overview.component';
import { NewsDetailComponent } from './containers/news-detail/news-detail.component';
import { NewsListCardComponent } from './components/news-list-card/news-list-card.component';
import { ContentBlockModule } from '../content-block/content-block.module';
import { ContactPageComponent } from './containers/contact-page/contact-page.component';
import { ContactOfficeComponent } from './components/contact-office/contact-office.component';
import { OfficeContactCardComponent } from './components/office-contact-card/office-contact-card.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { JobsPageComponent } from './containers/jobs-page/jobs-page.component';
import { JobsCardComponent } from './components/jobs-card/jobs-card.component';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { PeopleModule } from '../people/people.module';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';

@NgModule({
  declarations: [
    ContentPageComponent,
    NewsOverviewComponent,
    NewsDetailComponent,
    NewsListCardComponent,
    ContactPageComponent,
    ContactOfficeComponent,
    OfficeContactCardComponent,
    JobsPageComponent,
    JobsCardComponent,
  ],
  imports: [
    CommonModule,
    ContentRoutingModule,
    StoreModule.forFeature(fromContent.featureKey, fromContent.reducers, {
      metaReducers: fromContent.metaReducers,
    }),
    EffectsModule.forFeature(fromContent.effects),
    PeopleModule,
    InViewModule,
    Html5VideoPlayerModule,
    IconModule,
    InViewModule,
    CoreModule,
    TextUtilityModule,
    ContentBlockModule,
    DictionaryNgrxModule,
    CookieNgrxModule,
  ],
  exports: [],
})
export class ContentModule {}

<section class="news-overview spacer-bottom-l">
  <aside class="container-fluid news-overview__category">
    <div class="button-group">
      <button
        [routerLink]="'./'"
        [queryParams]="{ category: null }"
        [queryParamsHandling]="'merge'"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="'set--active'"
        (click)="clearNews()"
        class="button-link"
      >
        All
      </button>
      <button
        [routerLink]="'./'"
        [queryParams]="{ category: cat.id }"
        [queryParamsHandling]="'merge'"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="'set--active'"
        (click)="clearNews()"
        class="button-link"
        *ngFor="let cat of categories$ | async"
      >
        {{ cat.title }}
      </button>
    </div>
  </aside>
  <div class="container">
    <ul class="news-overview__grid mb-0">
      <li
        class="news-overview__grid__item"
        *ngFor="let newsItem of news$ | async | slice: 0:showTotalGrid; let i = index; trackBy: trackByFn"
        [style]="'--news-item-index:' + (i < showTotalGrid ? i : (i % showTotalGrid) - 1)"
        [class.news__item--featured]="i === 0"
      >
        <app-news-card [newsItem]="newsItem" (fstrInViewport)="spyYear(newsItem)" [once]="false"></app-news-card>
      </li>
    </ul>

    <div class="news-overview__buttons" *ngIf="!(loading$ | async)">
      <a class="button color-black button--small button--border" [routerLink]="'/pressroom'">
        <span>Pressroom</span>
      </a>

      <button class="button color-black button--small button--border type--more-news" (click)="showAllNews = true">
        <span>More news</span>
      </button>
    </div>

    <div class="container-xs pl-0 pr-0" *ngIf="showAllNews">
      <ul class="news-overview__list mb-0">
        <li
          class="news-overview__list__item"
          *ngFor="let newsItem of news$ | async | slice: showTotalGrid:999999; let i = index; trackBy: trackByFn"
        >
          <app-news-list-card [newsItem]="newsItem" (fstrInViewport)="spyYear(newsItem)" [once]="false"></app-news-list-card>
        </li>
      </ul>
    </div>

    <div class="news-overview__buttons spacer-bottom-xs spacer-top-xs" *ngIf="(loadMore$ | async) && showAllNews">
      <button class="button news__loadmore" [class.button--primary]="loadNewsError$ | async" (fstrInViewport)="loadMore()" [once]="false">
        <ng-container *ngIf="!(loadNewsError$ | async)">Loading more news...</ng-container>
        <ng-container *ngIf="loadNewsError$ | async">Load more news</ng-container>
      </button>
    </div>
  </div>

  <aside class="fixed-footer" [class.show--fixed-footer]="showDate">
    {{ spiedYear$ | async }}
  </aside>
</section>

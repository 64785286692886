<article class="contact-page spacer-bottom-s" *ngIf="page$ | async as page">
  <div class="section-inset-b" *ngIf="page.imageUrl">
    <app-header
      [fixedImage]="true"
      [lazyLoading]="'eager'"
      class="contact__header"
      [imageUrl]="page.imageUrl"
      [imageAnchor]="page.imageAnchor"
      [videoBg]="page.videoUrl"
      [title]="page.title"
    ></app-header>
  </div>
  <main class="contact-page__main">
    <ng-container *ngFor="let block of contentBlocks$ | async; let i = index" [ngSwitch]="block.blockType">
      <app-slider-block
        *ngSwitchCase="'block--gallery'"
        [contentBlock]="block"
        class="contact-page__block"
        [ngClass]="'type--' + i"
      ></app-slider-block>

      <div *ngSwitchCase="'block--text'">
        <div class="spacer-bottom-m spacer-top-m container-fluid" *ngIf="block.author === 'employees'">
          <!-- <pre>{{ block | json }}</pre> -->
          <div class="row">
            <div class="col-24 col-lg-4 offset-lg-2 contact-page__sidebar">
              <h2 class="h3 mb-1">{{ block.title }}</h2>
              <span class="contact-page__sidebar__text" [innerHTML]="block.text"></span>
            </div>

            <div class="col-24 col-lg-15 contact-page__content">
              <app-people-employees
                [items]="employees$ | async"
                [asContacts]="true"
                [isContainer]="false"
                [cols]="3"
              ></app-people-employees>
            </div>
          </div>
        </div>

        <!-- spacer-top-m spacer-bottom-xl -->
        <div class="spacer-bottom-m container-fluid" *ngIf="block.author === 'offices'">
          <div class="row">
            <div class="col-24 col-lg-4 offset-lg-2 contact-page__sidebar">
              <h2 class="h3 mb-1">{{ block.title }}</h2>
              <span class="contact-page__sidebar__text" [innerHTML]="block.text"></span>
            </div>

            <div class="col-24 col-lg-15 contact-page__content">
              <ul class="d-flex contact-page__contacts-list">
                <ng-container *ngFor="let office of offices$ | async">
                  <li class="mb-5 contact-page__contacts-list__item">
                    <app-office-contact-card [contact]="office"></app-office-contact-card>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

        <div class="spacer-bottom-xl container-fluid" *ngIf="block.author === 'public-relations'">
          <div class="row">
            <div class="col-24 col-lg-4 offset-lg-2 contact-page__sidebar">
              <h2 class="h3 mb-1">{{ block.title }}</h2>
              <span class="contact-page__sidebar__text" [innerHTML]="block.text"></span>
            </div>
            <div class="col-24 col-lg-15 d-flex align-items-end contact-page__content">
              <!-- <a class="button button--small color-black button--border" [href]="'pressroom-url' | fromDictionary"> -->
              <a class="button button--small color-black button--border" [routerLink]="'/pressroom'">
                <span>Get a presskit</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </main>
</article>

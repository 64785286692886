import { Injectable } from '@angular/core';


import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import * as fromRoot from '../../store';
import * as fromStore from '../store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewsItemProjectsGuard  {
  constructor(private store: Store<fromStore.ContentState>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getNewsProjectsLoaded).pipe(
      withLatestFrom(this.store.select(fromRoot.getRouterState)),
      tap(([loaded, routerState]) => {
        if (!loaded) {
          this.store.dispatch(fromStore.LoadRelatedProjects({ newsId: routerState.state.params.newsId }));
        }
      }),
      map(([loaded, routerState]) => loaded),
      filter((loaded: boolean) => loaded),
      take(1)
    );
  }
}

import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Office } from '../../../people/models';
import { OfficeContact } from '../../models/office-contact.model';

@Component({
  selector: 'app-office-contact-card',
  templateUrl: './office-contact-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeContactCardComponent implements OnInit {
  @Input() contact: Office;

  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, inject, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { News } from '../../models';
import { Store } from '@ngrx/store';
import { ContentState, getNewsRelatedProjects, getRelatedNews, getSelectedNews } from '../../store';
import { Project } from '../../../projects/models';
import { takeUntil } from 'rxjs/operators';
import { SetTags, SetTitle } from '../../../store';
import { StringUtility } from '@teamfoster/sdk/text-utility';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsDetailComponent implements OnInit, OnDestroy {
  news$: Observable<News>;
  projects$: Observable<Project[]>;
  related$: Observable<News[]>;
  news: News;
  sliderConfig = {
    gap: 5,
    showArrows: true,
    showBullets: false,
    perView: 1,
    startAt: 0,
    listenToEvents: true,
    type: 'carousel',
  };
  headerImageSizes = { w: 1400, h: 933 }; // image size for header
  cookiesAccepted$: Observable<CookiePreferences>;
  private _unsubscribe$ = new Subject<void>();
  private platformId = inject(PLATFORM_ID);

  constructor(private store: Store<ContentState>) {}

  ngOnInit(): void {
    this.projects$ = this.store.select(getNewsRelatedProjects);
    this.related$ = this.store.select(getRelatedNews);
    this.news$ = this.store.select(getSelectedNews);
    this.news$.pipe(takeUntil(this._unsubscribe$)).subscribe(n => {
      this.news = n;
      if (n?.gallery?.length > 0) {
        this.sliderConfig.showArrows = true;
      }
      if (n) {
        this.store.dispatch(SetTitle({ title: `${this.news.title} | KCAP` }));
        this.store.dispatch(
          SetTags({
            properties: {
              'description': StringUtility.Truncate(StringUtility.StripHTML(this.news.intro || this.news.text), 157, '...'),
              'og:image': this.news.imageUrl,
              'og:title': `${this.news.title} - KCAP`,
              'og:description': StringUtility.Truncate(StringUtility.StripHTML(this.news.intro || this.news.text), 157, '...'),
            },
          })
        );
      }
    });
    this.cookiesAccepted$ = this.store.select(getCookiePreferences);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}

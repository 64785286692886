import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { News, NewsCategory } from '../../models';
import {
  ContentState,
  getAllNews,
  LoadMoreNews,
  getNewsLoadMore,
  getOrderedNews,
  getNewsError,
  getAllNewsCategories,
  ClearNews,
  getNewsLoading,
} from '../../store';
import { Store } from '@ngrx/store';
import { debounce, debounceTime, distinct, map, takeUntil } from 'rxjs/operators';
import { SetTitle } from '../../../store';

@Component({
  selector: 'app-news-overview',
  templateUrl: './news-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsOverviewComponent implements OnInit, OnDestroy {
  news$: Observable<News[]>;
  categories$: Observable<NewsCategory[]>;
  loading$: Observable<boolean>;

  loadMore$: Observable<boolean>;
  loadNewsError$: Observable<any>;
  showTotalGrid = 25;
  showDate = false;
  $unsubscribe = new Subject<void>();
  private _spiedYear$ = new BehaviorSubject<string>('');
  showAllNews = false;
  spiedYear$: Observable<string>;

  constructor(private store: Store<ContentState>, private cd: ChangeDetectorRef) {
    this.news$ = this.store.select(getOrderedNews);
    this.categories$ = this.store.select(getAllNewsCategories);

    this.loading$ = this.store.select(getNewsLoading);

    this.loadMore$ = this.store.select(getNewsLoadMore);
    this.loadNewsError$ = this.store.select(getNewsError).pipe(map(a => (a ? true : false)));
  }

  ngOnInit(): void {
    this.spiedYear$ = this._spiedYear$.pipe(debounceTime(100));

    fromEvent(window, 'scroll')
      .pipe(debounceTime(10))
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(a => {
        // show scrollbar after windowinnerheight (window.innerHeight)
        if (window.scrollY > 200) {
          this.showDate = true;
        } else {
          this.showDate = false;
        }
        this.cd.detectChanges();
      });

    this.store.dispatch(SetTitle({ title: `News | KCAP` }));
  }

  loadMore() {
    this.store.dispatch(LoadMoreNews());
  }

  spyYear(newsItem: News) {
    this._spiedYear$.next(new Date(newsItem.updatedOn).getFullYear().toString());
  }

  trackByFn(index: number, item: News) {
    return item.id;
  }

  clearNews() {
    this.store.dispatch(ClearNews());
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}

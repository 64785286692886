import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { Observable } from 'rxjs';
import { getSelectedContentBlocks } from '../../../content-block/store';
import { ContentPage } from '../../models';
import { getOrderedOffices, getSelectedContentPage } from '../../store';
import { ContentBlock } from '../../../content-block/models';
import { Employee, Office } from '../../../people/models';
import { getAllContactEmployees } from '../../../people/store';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPageComponent implements OnInit {
  contentBlocks$: Observable<ContentBlock[]>;
  page$: Observable<ContentPage>;
  offices$: Observable<Office[]>;
  employees$: Observable<Employee[]>;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.contentBlocks$ = this.store.select(getSelectedContentBlocks);
    this.page$ = this.store.select(getSelectedContentPage);
    this.offices$ = this.store.select(getOrderedOffices);
    this.employees$ = this.store.select(getAllContactEmployees);
  }
}
